define([
        'modules/upx/collections/collection',
        '../models/ProductPrice',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ProductsModule',
            object: 'ProductPrice',
            collection_method: 'listProductPrices',
            model: Model
        });
    }
);