define([
        'modules/upx/collections/collection',
        '../models/OrderProvider',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'OnlineFoodOrderModule',
            object: 'OrderProvider',
            collection_method: 'listOrderProviders',
            model: Model
        });
    }
);