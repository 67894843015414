define([
        'modules/upx/models/model',
        './structs/OnlineOrder'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'OnlineFoodOrderModule',

            object: 'OnlineOrder',
            confirm: function (parameters, ajaxOptions) {
                return this.callObjectMethod('confirmOnlineOrder',parameters, ajaxOptions);
            },
            markAsInKitchen: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOnlineOrderAsInKitchen',parameters, ajaxOptions);
            },
            finish: function (parameters, ajaxOptions) {
                return this.callObjectMethod('finishOnlineOrder',parameters, ajaxOptions);
            },
            newShopOrderFor: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newShopOrderForOnlineOrder',parameters, ajaxOptions);
            },
            cancel: function (parameters, ajaxOptions) {
                return this.callObjectMethod('cancelOnlineOrder',parameters, ajaxOptions);
            },
            getVolatileOrder: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getOnlineOrderVolatileOrder',parameters, ajaxOptions);
            }
        });
    }
);