define([
        'modules/upx/models/model',
        './structs/Configuration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ProductsModule',
            idAttribute: 'id',
            object: 'Configuration',
            getMy: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getMyConfiguration',parameters, ajaxOptions);
            },
            getDefaultCurrency: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getDefaultCurrency',parameters, ajaxOptions);
            },
            getDefaultTaxRate: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getDefaultTaxRate',parameters, ajaxOptions);
            }
        });
    }
);