define([
        'modules/upx/models/model',
        './structs/ExternalGiftCard'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',

            object: 'ExternalGiftCard',
            find: function (parameters, ajaxOptions) {
                return this.callObjectMethod('findExternalGiftCard',parameters, ajaxOptions);
            }
        });
    }
);